<template>
      <v-card
        elevation="0"
        :class="$vuetify.breakpoint.xs?'mt-1 text-caption mb-2' :'mt-2 mb-6 mx-0'"
      >
        <!-- ナビゲーション -->
        <div v-if="onlyType!='content'">
          <div class="d-flex mb-0" style="width:100%;">
            <div class="mb-3 pt-0 font-weight-bold text-right" style="width:100%;">{{ item.navi.limit - item.navi.usage }} 個 空き  / {{ item.navi.limit }} 個 </div>
          </div>
          <div class="d-flex" style="width:100%; border:1px solid grey; border-radius:3px;">
            <v-tooltip top   >
              <template v-slot:activator="{ on, attrs }">
                <v-progress-linear 
                  color="primary"
                  height="25"
                  value="100"
                  :style="`width:${item.navi.usageRate}%;`"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-progress-linear>
              </template>
                <div> <div class="text-center ma-0">ナビゲーション</div><div> {{ item.navi.usage}}個</div></div>
              </v-tooltip>
          </div>

         
          <div class="d-flex">
            <div class="mr-6 my-1">
              <div class="font-weight-bold"> <v-icon dense color="primary">{{icons.squareRounded}}</v-icon>ナビゲーション</div>
              <div class="caption text-right"> {{ item.navi.usage}}個</div>
            </div>
          </div>
        </div>

        <!-- コンテンツ -->
        <div >
          <div class="d-flex mb-2" style="width:100%;"  v-if="onlyType!='content'">
            <div class="mb-3  font-weight-bold text-right" style="width:100%;"> {{storageText}} </div>
          </div>
          <div class="d-flex" style="width:100%; border:1px solid grey; border-radius:3px;">
            <v-tooltip :top="onlyType!='content'" :bottom="onlyType=='content'"   v-for="item in storageRateDict" :key="item.name">
              <template v-slot:activator="{ on, attrs }">
                <v-progress-linear 
                  :color="item.color"
                  height="25"
                  value="100"
                  :style="`width:${item.rate}%;`"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-progress-linear>
              </template>
                <div v-if="item.name!='rest'" > <div class="text-center ma-0">{{ item.dispName}}</div><div> {{ item.usage}}GB</div></div>
                <div v-else><div class="text-center ma-0">空き</div><div> {{ restText}}</div></div>
              </v-tooltip>
          </div>

          <div class="d-flex" v-if="onlyType!='content'">
            <div v-for="item in storageRateDict" :key="item.name"  :class="$vuetify.breakpoint.xs?(item.name=='movie')?'my-1':'mr-2 my-1 ':'mr-6 my-1' "  >
              <div>
                <div v-if="item.name!='rest'" class="font-weight-bold"> <v-icon dense :color="item.color">{{icons.squareRounded}}</v-icon>{{item.dispName}}</div>
                <div v-if="item.name!='rest'" class="caption text-right"> {{ item.usage}} GB</div>
              </div>
            </div>
          </div>

        </div>

  
    
      </v-card>

</template>

<script>
import { mdiSquareRounded } from '@mdi/js';

// const  StorageMini = () => import('@/components/StorageMini.vue');
  export default {
    name: 'StorageBar',
    props:["item","data","loading","onlyType"],
    // components:{
    //   StorageMini
    // },
    data: () => ({
      icons:{"squareRounded":mdiSquareRounded},
      storageRateDict:[
        {name:"3Dmodel","dispName":"3Dモデル","rate":0,"usage":0,"color":"#ED3B79"},
        {name:"image","dispName":"画像","rate":0,"usage":0,"color":"#F6D55C"},
        {name:"pdf","dispName":"PDF","rate":0,"usage":0,"color":"#3CAEA3"},
        {name:"sound","dispName":"音声","rate":0,"usage":0,"color":"purple"},
        {name:"movie","dispName":"動画","rate":0,"usage":0,"color":"#173F5F"},
        {name:"rest","dispName":"残り","rate":0,"usage":0,"color":"white"}
      ]
    }),


    computed:{
      storageText(){
        let unit = (this.item.total.limit < 1) ? "MB" : "GB";
        let times = (this.item.total.limit < 1) ? 1000 : 1;
        return `${Math.floor( (this.item.total.limit*times - this.item.total.usage*times) * 100 ) / 100}  ${unit} 空き / ${this.item.total.limit*times} ${unit}`
      },

      restText(){
         let unit = "GB";
        let times = 1;
        return `${Math.floor( (this.item.total.limit*times - this.item.total.usage*times) * 100 ) / 100}  ${unit} `
      }

    },

  

    mounted() {
      this.makeStorageRate()
    },

    watch:{
      loading(newVal){
        if(newVal==false)this.makeStorageRate()
      }
    },


    methods:{

      makeStorageRate(){
        let total = this.item.total;
        this.storageRateDict.forEach((elem,index) => {
          if(elem.name == "rest"){
            this.storageRateDict[index].rate = 100 - total.usageRate;
            return;
          }
          this.storageRateDict[index].rate = (this.item[elem.name].usage / total.limit) * 100;
          this.storageRateDict[index].usage = Math.ceil(this.item[elem.name].usage * 100) / 100;
        })
      }

    }
  }
</script>



